<template>
  <div
    class="views_shopping_mail_featured_modules_teaching_package_unit_hover bbox"
  >
    <div class="info_group">
      <img
        v-if="picture"
        :src="`${$img_url}${picture}`"
        :style="{height: img_height?`${img_height}px`:'100%'}"
        alt=""
        class="img"
        ref="img"
      >
      <div
        v-if="!picture"
        class="no_picture_group un_sel bbox d-flex align-center justify-center"
        ref="no_picture"
        :style="{height: img_height?`${img_height}px`:'100%'}"
      >
        <div class="pic_title">
          {{ title }}
        </div>
      </div>
      <div class="title mt-10">{{title}}</div>
      <div class="descript pt-6 mb-10" v-html="content"></div>
      <div class="icon_group d-flex align-center justify-space-between pt-10">
        <div class="time_group d-flex align-center">
          <img src="~@/assets/images/shopping_mail/time_icon.png" alt="" class="icon">
          <div class="text">{{$moment(create_time).format('YYYY年MM月DD日')}}</div>
        </div>
        <div class="data_group d-flex align-center">
          <div class="item_group d-flex align-center mr-10"
            v-if="sale"
          >
            <img src="~@/assets/images/shopping_mail/sale_icon.png" alt="" class="icon">
            <div class="text">{{sale}}</div>
          </div>
          <div class="item_group d-flex align-center"
            v-if="readamount"
          >
            <img src="~@/assets/images/shopping_mail/view_icon.png" alt="" class="icon">
            <div class="text">{{readamount}}</div>
          </div>
        </div>
      </div>
      <div class="category_group py-10"
        v-if="0"
      >会展专业 / 会展营销 / 市场营销 </div>
    </div>

    <div class="payment_group d-flex align-center justify-space-between px-10 py-16">
      <div class="price_group">
        <div class="price pb-4">¥{{price}}</div>
        <!-- <div class="hint">包含绑定账户数：1-100个</div> -->
      </div>
      <div class="opeation_group">
        <div class="button"
          @click.stop="doConsulting();"
        >{{price === 0 ? '免费领取':'咨询购买'}}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
   props: {
    pselected: {
      type: Object,
      default: undefined,
    },
    ppackage: {
      type: Object,
      default: undefined,
    }
  },
  data(){
    return {
      img_height: undefined,
    }
  },
  computed: {
    picture(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.pic;
      }
      if(ppackage){
        return ppackage.webpic;
      }
      return '';
    },
    title(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.title;
      }
      if(ppackage){
        return ppackage.title;
      }
      return '';
    },
    content(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.ppackage?.content || '';
      }
      if(ppackage){
        return ppackage.content;
      }
      return '';
    },
    create_time(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.createTime;
      }
      if(ppackage){
        return ppackage.createTime;
      }
      return '';
    },
    price(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.ppackage.price/100;
      }
      if(ppackage){
        return ppackage.price/100;
      }
      return false;
    },
    sale(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.ppackage.sale;
      }
      if(ppackage){
        return ppackage.sale;
      }
      return undefined;
    },
    readamount(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.ppackage.readamount;
      }
      if(ppackage){
        return ppackage.readamount;
      }
      return undefined;
    }
  },
  mounted(){
    this.$nextTick(function(){
      this.init();
    })
  },
  methods: {
    init(){
      this.initSize();
    },
    initSize(){
      // const {clientWidth} = this.$refs.img;
      let clientWidth
      if (this.$refs.img) {
        clientWidth = this.$refs.img.clientWidth
      }
      if (this.$refs.no_picture) {
        clientWidth = this.$refs.no_picture.clientWidth
      }
      const calc_height = this.calculativeHeight({width: clientWidth+80}); //按照放大后的宽度计算
      this.img_height = calc_height;
    },
    /**
     * 按照16:9转换高度
     */
    calculativeHeight(params={}){
      const {width} = params;
      const height = (9 * width) / 16;
      return height;
    },
    async doConsulting(){
      const {price, pselected, ppackage} = this
      let obj = pselected?.ppackage ?? ppackage
      console.log({price, pselected, ppackage, obj})
      if(obj.buy){
        this.$notice({desc: '您已购买过此教学包'})
        return
      }
      if(price === 0){
        const res = obj.getFree()
        if(res){
          this.$notice({desc: '领取成功', tpye: 'success'})
        }
        return
      }
      let paramId
      if (pselected) {
        paramId = pselected.ppackage.id
      }
      if (ppackage) {
        paramId = ppackage.id
      }
      this.$consulting({
        title: '咨询购买',
        subtitle: '您好，请确认并填写以下信息，方便工作人员与您及时联系。',
        enquireType: 0,
        paramId,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_featured_modules_teaching_package_unit_hover{
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  .img{
    width: 100%;
    display: block;
    border-radius: 4px;
  }
  .no_picture_group{
    width: 100%;
    //display: block;
    border-radius: 4px;
    background-color: #aaa;
    .pic_title{
      font-size: 18px;
      color: #fff;
      width: 100%;
      padding: 0 10px;
      text-align: center;
      word-break: break-all;
    }
  }
  .title{
    @include multi_line_intercept(2);
    width: 100%;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
  }
  .info_group{
    padding: 10px;
  }
  .descript{
    @include multi_line_intercept(4);
    font-size: 14px;
    color: #999999;
    line-height: 24px;
    width: 100%;
    border-bottom: 4px solid #f8f8f8;
    max-height: (24*4)+6px;
    overflow: hidden;
  }
  .icon_group{
    font-size: 12px;
    color: #666666;
    line-height: 16px;
    .icon{
      margin-right: 4px;
    }
  }
  .category_group{
    font-size: 12px;
    color: #AAAAAA;
    line-height: 16px;
  }
  .payment_group{
    background-color: #f0f0f0;
    .price{
      font-size: 16px;
      font-weight: bold;
      color: #F3580C;
      line-height: 21px;
    }
    .hint{
      font-size: 12px;
      color: #884706;
      line-height: 16px;
    }
    .button{
      width: 120px;
      height: 34px;
      background-color: #2BB06C;
      border-radius: 4px;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 34px;
      text-align: center;
    }
  }
}

</style>
